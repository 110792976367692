import React from 'react';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import LatestPostsPageLayout from '../components/PageLayouts/Latest/LatestPostsPageLayout';

import useLatestRecipePosts from '../hooks/useLatestRecipePosts';
import useLatestThoughtsPosts from '../hooks/useLatestThoughtsPosts';
import useLatestHealthPosts from '../hooks/useLatestHealthPosts';
import useSiteMetadata from '../hooks/useSiteMetadata';

const LatestPage = () => {
    const latestThoughtsPosts = useLatestThoughtsPosts();
    const latestResipePosts = useLatestRecipePosts();
    const latestHealthPosts = useLatestHealthPosts();

    const { siteUrl, categoryMap } = useSiteMetadata();
    const pageTitle = 'Latest posts';
    const url = siteUrl + '/latest';
    const pageDescription = 'Latest posts from all categories';

    return (
        <Theme>
            <PageMeta title={pageTitle} url={url} pageDescription={pageDescription} />
            <PageContainer>
                <NavBar page="latest" />
                <MainContainer>
                    <LatestPostsPageLayout
                        postsData={[latestHealthPosts, latestResipePosts, latestThoughtsPosts]}
                        categoryMap={categoryMap}
                    />
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default LatestPage;
